/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.132.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { Codeliste } from '../models';
// @ts-ignore
import { ErrorRest } from '../models';
/**
 * CodelistResourceApi - axios parameter creator
 * @export
 */
export const CodelistResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Liefert alle Codelisten aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert alle Codelisten aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCodelisten: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/codelisten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert eine Codeliste aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert eine Codeliste aus
         * @param {string} name Codelistname
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeliste: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getCodeliste', 'name', name)
            const localVarPath = `/konfigurationen/codelisten/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStammCodelisten: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/stammcodelisten/datei`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodelistResourceApi - functional programming interface
 * @export
 */
export const CodelistResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodelistResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Liefert alle Codelisten aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert alle Codelisten aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCodelisten(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Codeliste>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCodelisten(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodelistResourceApi.getAllCodelisten']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert eine Codeliste aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert eine Codeliste aus
         * @param {string} name Codelistname
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCodeliste(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Codeliste>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCodeliste(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodelistResourceApi.getCodeliste']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStammCodelisten(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStammCodelisten(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CodelistResourceApi.getStammCodelisten']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CodelistResourceApi - factory interface
 * @export
 */
export const CodelistResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodelistResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Liefert alle Codelisten aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert alle Codelisten aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCodelisten(options?: any): AxiosPromise<Array<Codeliste>> {
            return localVarFp.getAllCodelisten(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert eine Codeliste aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert eine Codeliste aus
         * @param {string} name Codelistname
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCodeliste(name: string, options?: any): AxiosPromise<Codeliste> {
            return localVarFp.getCodeliste(name, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStammCodelisten(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getStammCodelisten(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodelistResourceApi - object-oriented interface
 * @export
 * @class CodelistResourceApi
 * @extends {BaseAPI}
 */
export class CodelistResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Liefert alle Codelisten aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert alle Codelisten aus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodelistResourceApi
     */
    public getAllCodelisten(options?: RawAxiosRequestConfig) {
        return CodelistResourceApiFp(this.configuration).getAllCodelisten(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert eine Codeliste aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert eine Codeliste aus
     * @param {string} name Codelistname
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodelistResourceApi
     */
    public getCodeliste(name: string, options?: RawAxiosRequestConfig) {
        return CodelistResourceApiFp(this.configuration).getCodeliste(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodelistResourceApi
     */
    public getStammCodelisten(options?: RawAxiosRequestConfig) {
        return CodelistResourceApiFp(this.configuration).getStammCodelisten(options).then((request) => request(this.axios, this.basePath));
    }
}

