/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.132.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { TransferMessageDetailRest } from '../models';
// @ts-ignore
import { VerfahrenUebersichtMetadatenRest } from '../models';
/**
 * MetadatenExportResourceApi - axios parameter creator
 * @export
 */
export const MetadatenExportResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVerfahrenInGeometry: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getAllVerfahrenInGeometry', 'body', body)
            const localVarPath = `/verfahren/metadaten/geometry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Bereitstellung von Planmetadaten.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Bereitstellung von Planmetadaten.
         * @param {string} planID planID des Verfahrens
         * @param {GetMetadatenFormat} format das Metadaten XMl-Format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadaten: async (planID: string, format: GetMetadatenFormat, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getMetadaten', 'planID', planID)
            // verify required parameter 'format' is not null or undefined
            assertParamExists('getMetadaten', 'format', format)
            const localVarPath = `/verfahren/{planID}/metadaten`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {PublishMetadatenFormat} format 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishMetadaten: async (planID: string, format: PublishMetadatenFormat, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('publishMetadaten', 'planID', planID)
            // verify required parameter 'format' is not null or undefined
            assertParamExists('publishMetadaten', 'format', format)
            const localVarPath = `/verfahren/{planID}/metadaten`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetadatenExportResourceApi - functional programming interface
 * @export
 */
export const MetadatenExportResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetadatenExportResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVerfahrenInGeometry(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VerfahrenUebersichtMetadatenRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVerfahrenInGeometry(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetadatenExportResourceApi.getAllVerfahrenInGeometry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Bereitstellung von Planmetadaten.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Bereitstellung von Planmetadaten.
         * @param {string} planID planID des Verfahrens
         * @param {GetMetadatenFormat} format das Metadaten XMl-Format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetadaten(planID: string, format: GetMetadatenFormat, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetadaten(planID, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetadatenExportResourceApi.getMetadaten']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {PublishMetadatenFormat} format 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishMetadaten(planID: string, format: PublishMetadatenFormat, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferMessageDetailRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishMetadaten(planID, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetadatenExportResourceApi.publishMetadaten']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetadatenExportResourceApi - factory interface
 * @export
 */
export const MetadatenExportResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetadatenExportResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVerfahrenInGeometry(body: string, options?: any): AxiosPromise<Array<VerfahrenUebersichtMetadatenRest>> {
            return localVarFp.getAllVerfahrenInGeometry(body, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Bereitstellung von Planmetadaten.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Bereitstellung von Planmetadaten.
         * @param {string} planID planID des Verfahrens
         * @param {GetMetadatenFormat} format das Metadaten XMl-Format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadaten(planID: string, format: GetMetadatenFormat, options?: any): AxiosPromise<string> {
            return localVarFp.getMetadaten(planID, format, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {PublishMetadatenFormat} format 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishMetadaten(planID: string, format: PublishMetadatenFormat, options?: any): AxiosPromise<TransferMessageDetailRest> {
            return localVarFp.publishMetadaten(planID, format, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetadatenExportResourceApi - object-oriented interface
 * @export
 * @class MetadatenExportResourceApi
 * @extends {BaseAPI}
 */
export class MetadatenExportResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_READ or EIGENE_VERFAHREN_READ or ADMIN
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadatenExportResourceApi
     */
    public getAllVerfahrenInGeometry(body: string, options?: RawAxiosRequestConfig) {
        return MetadatenExportResourceApiFp(this.configuration).getAllVerfahrenInGeometry(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Bereitstellung von Planmetadaten.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Bereitstellung von Planmetadaten.
     * @param {string} planID planID des Verfahrens
     * @param {GetMetadatenFormat} format das Metadaten XMl-Format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadatenExportResourceApi
     */
    public getMetadaten(planID: string, format: GetMetadatenFormat, options?: RawAxiosRequestConfig) {
        return MetadatenExportResourceApiFp(this.configuration).getMetadaten(planID, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
     * @param {string} planID 
     * @param {PublishMetadatenFormat} format 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadatenExportResourceApi
     */
    public publishMetadaten(planID: string, format: PublishMetadatenFormat, options?: RawAxiosRequestConfig) {
        return MetadatenExportResourceApiFp(this.configuration).publishMetadaten(planID, format, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetMetadatenFormat = {
    DCAT_AP_PLU: 'DCAT_AP_PLU',
    IGC: 'IGC',
    CSW: 'CSW',
    CSW_T: 'CSW_T',
    XPLAN_LP: 'XPLAN_LP',
    XPLAN_BP: 'XPLAN_BP',
    XPLAN_FP: 'XPLAN_FP'
} as const;
export type GetMetadatenFormat = typeof GetMetadatenFormat[keyof typeof GetMetadatenFormat];
/**
 * @export
 */
export const PublishMetadatenFormat = {
    DCAT_AP_PLU: 'DCAT_AP_PLU',
    IGC: 'IGC',
    CSW: 'CSW',
    CSW_T: 'CSW_T',
    XPLAN_LP: 'XPLAN_LP',
    XPLAN_BP: 'XPLAN_BP',
    XPLAN_FP: 'XPLAN_FP'
} as const;
export type PublishMetadatenFormat = typeof PublishMetadatenFormat[keyof typeof PublishMetadatenFormat];
