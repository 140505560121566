/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.132.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { BlacklistTerminRest } from '../models';
// @ts-ignore
import { ErrorRest } from '../models';
/**
 * BlacklistResourceApi - axios parameter creator
 * @export
 */
export const BlacklistResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> delete blacklist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary delete blacklist ics-Datei
         * @param {File} blacklist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlacklist: async (blacklist: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blacklist' is not null or undefined
            assertParamExists('deleteBlacklist', 'blacklist', blacklist)
            const localVarPath = `/konfigurationen/blacklist/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (blacklist !== undefined) { 
                localVarFormParams.append('blacklist', blacklist as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Löscht Blacklist-Termine</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht Blacklist-Termine
         * @param {Array<number>} requestBody ID der Termine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlacklist1: async (requestBody: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteBlacklist1', 'requestBody', requestBody)
            const localVarPath = `/konfigurationen/blacklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBlacklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/blacklist/datei`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Blackliste aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Blackliste aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlacklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/blacklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> upload blacklist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary upload blacklist ics-Datei
         * @param {File} blacklist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBlacklist: async (blacklist: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'blacklist' is not null or undefined
            assertParamExists('uploadBlacklist', 'blacklist', blacklist)
            const localVarPath = `/konfigurationen/blacklist/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (blacklist !== undefined) { 
                localVarFormParams.append('blacklist', blacklist as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlacklistResourceApi - functional programming interface
 * @export
 */
export const BlacklistResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlacklistResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> delete blacklist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary delete blacklist ics-Datei
         * @param {File} blacklist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBlacklist(blacklist: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBlacklist(blacklist, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlacklistResourceApi.deleteBlacklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Löscht Blacklist-Termine</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht Blacklist-Termine
         * @param {Array<number>} requestBody ID der Termine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBlacklist1(requestBody: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBlacklist1(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlacklistResourceApi.deleteBlacklist1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadBlacklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadBlacklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlacklistResourceApi.downloadBlacklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Blackliste aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Blackliste aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlacklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlacklistTerminRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlacklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlacklistResourceApi.getBlacklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> upload blacklist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary upload blacklist ics-Datei
         * @param {File} blacklist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBlacklist(blacklist: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBlacklist(blacklist, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BlacklistResourceApi.uploadBlacklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BlacklistResourceApi - factory interface
 * @export
 */
export const BlacklistResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlacklistResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> delete blacklist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary delete blacklist ics-Datei
         * @param {File} blacklist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlacklist(blacklist: File, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBlacklist(blacklist, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Löscht Blacklist-Termine</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht Blacklist-Termine
         * @param {Array<number>} requestBody ID der Termine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBlacklist1(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBlacklist1(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBlacklist(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadBlacklist(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Blackliste aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Blackliste aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlacklist(options?: any): AxiosPromise<Array<BlacklistTerminRest>> {
            return localVarFp.getBlacklist(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> upload blacklist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary upload blacklist ics-Datei
         * @param {File} blacklist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBlacklist(blacklist: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadBlacklist(blacklist, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlacklistResourceApi - object-oriented interface
 * @export
 * @class BlacklistResourceApi
 * @extends {BaseAPI}
 */
export class BlacklistResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> delete blacklist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary delete blacklist ics-Datei
     * @param {File} blacklist 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistResourceApi
     */
    public deleteBlacklist(blacklist: File, options?: RawAxiosRequestConfig) {
        return BlacklistResourceApiFp(this.configuration).deleteBlacklist(blacklist, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Löscht Blacklist-Termine</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Löscht Blacklist-Termine
     * @param {Array<number>} requestBody ID der Termine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistResourceApi
     */
    public deleteBlacklist1(requestBody: Array<number>, options?: RawAxiosRequestConfig) {
        return BlacklistResourceApiFp(this.configuration).deleteBlacklist1(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> isAuthenticated()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistResourceApi
     */
    public downloadBlacklist(options?: RawAxiosRequestConfig) {
        return BlacklistResourceApiFp(this.configuration).downloadBlacklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Blackliste aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert die Blackliste aus.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistResourceApi
     */
    public getBlacklist(options?: RawAxiosRequestConfig) {
        return BlacklistResourceApiFp(this.configuration).getBlacklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> upload blacklist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary upload blacklist ics-Datei
     * @param {File} blacklist 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlacklistResourceApi
     */
    public uploadBlacklist(blacklist: File, options?: RawAxiosRequestConfig) {
        return BlacklistResourceApiFp(this.configuration).uploadBlacklist(blacklist, options).then((request) => request(this.axios, this.basePath));
    }
}

